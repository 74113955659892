import { ApolloClient, InMemoryCache } from '@apollo/client'

export const healthClient = new ApolloClient({
  uri: 'https://graph.butter.xyz/graphql',
  cache: new InMemoryCache(),
})

export const mantleTestnetHealthClient = new ApolloClient({
  uri: 'https://graph.testnet.mantle.xyz/graphql',
  cache: new InMemoryCache(),
})

//blockClient &  client are for MAINNET // mantleTestnetBlockClient & mantleTestnetClient are for TESTNET
export const blockClient = new ApolloClient({
  uri: 'https://graph.butter.xyz/subgraphs/name/butterxyz/mantle-blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const client = new ApolloClient({
  uri: 'https://graph.butter.xyz/subgraphs/name/butterxyz/v3-subgraph',
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const mantleTestnetBlockClient = new ApolloClient({
  uri: 'https://graph.testnet.mantle.xyz/subgraphs/name/twopointfive/ethereum-blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const mantleTestnetClient = new ApolloClient({
  uri: 'https://graph.testnet.mantle.xyz/subgraphs/name/twopointfive/v3-subgraph',
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
