import { NetworkInfo, SUPPORTED_NETWORK_VERSIONS} from 'constants/networks'

export function networkPrefix(activeNewtork: NetworkInfo) {
  // CHANGE NEXT LINE TO SET DEFAULT NETWORK
  const isEthereum = activeNewtork === SUPPORTED_NETWORK_VERSIONS[0]
  if (isEthereum) {
    return '/'
  }
  const prefix = '/' + activeNewtork.route.toLocaleLowerCase() + '/'
  return prefix
}
