import OPTIMISM_LOGO_URL from '../assets/images/optimism.svg'
import ARBITRUM_LOGO_URL from '../assets/images/arbitrum.svg'
import ETHEREUM_LOGO_URL from '../assets/images/ethereum-logo.png'
import POLYGON_LOGO_URL from '../assets/images/polygon-logo.png'
import CELO_LOGO_URL from '../assets/images/celo-logo.svg'
import BNB_LOGO_URL from '../assets/images/bnb-logo.svg'
import { SupportedChainId } from 'constants/chains'

export enum SupportedNetwork {
  ETHEREUM,
  ARBITRUM,
  OPTIMISM,
  POLYGON,
  CELO,
  BNB,
  MANTLE,
  MANTLE_TESTNET,
}

export type NetworkInfo = {
  chainId: SupportedChainId
  id: SupportedNetwork
  route: string
  name: string
  imageURL: string
  bgColor: string
  primaryColor: string
  secondaryColor: string
  blurb?: string
}

export const MantleNetworkInfo: NetworkInfo = {
  chainId: 5000,
  id: SupportedNetwork.MANTLE,
  route: 'mantle',
  name: 'Mantle',
  bgColor: '#648D8A',
  primaryColor: '#648D8A',
  secondaryColor: '#648D8A',
  imageURL: 'https://www.mantle.xyz/logo-light.svg',
  blurb: '',
}

export const MantleTestnetNetworkInfo: NetworkInfo = {
  chainId: 5001,
  id: SupportedNetwork.MANTLE_TESTNET,
  route: 'mantle-testnet',
  name: 'Mantle Testnet',
  bgColor: '#AFFE02',
  primaryColor: '#AFFE02',
  secondaryColor: '#AFFE02',
  imageURL: 'https://www.mantle.xyz/logo-light.svg',
  blurb: '',
}

export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] =
  process.env.NODE_ENV === false && 'production' ? [MantleNetworkInfo] : [MantleNetworkInfo]
